import React from "react"

import Layout from "../components/layout"

const AboutPage = () => {
  return (
    <Layout>
    <div>
    <h5><a href="https://www.linkedin.com/in/lexwraith">LinkedIn</a></h5>
    <h5><a href="mailto:michael@modernliteracy.org">Email</a></h5>
    </div>
    </Layout>
  )
}

export default AboutPage
